import * as React from "react"
import PropTypes from "prop-types"
import { Container } from 'react-bootstrap';
// import Image from "./elements/image"
import ScrollParallax from "./elements/scrollParallax"

const HorizontalSlider = ({content}) => {
  return(
    <Container className=" pt-0 pb-0 horizontal-slider">
        <ScrollParallax 
          content={content}
        />
    </Container>
  )
}

HorizontalSlider.propTypes = {
  content: PropTypes.array,
}

export default HorizontalSlider