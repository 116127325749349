import * as React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import Image from "./elements/image"
import LabelsWrapper from "./elements/labels-wrapper"
// import { Link } from "gatsby"

const Project = ({
  type,
  image,
  title,
  labels,
  offset = 0,
  link,
  comingSoon = false,
  delay=0,
  animationDuration=0
}) => {
  let span = 5
  if (type === "large") {
    span = 6
  }
  if (type === "small") {
    span = 5
  }
  if (type === "wide") {
    span = 8
  }
  return (
    <Col
      xs={12}
      md={{ span: span, offset: offset }}
      className={`${type}-project-wrapper project-wrapper`}
      data-sal="slide-up"
      data-sal-delay={delay}
      data-sal-easing="easeOutCirc"
      data-sal-duration={animationDuration}
    >
      {/* <Link to={link}> */}
      <div>

        <Image imgsrc={image}/>
      </div>
      {comingSoon ? (
        <div className="project-coming-soon">
          <div className="btn btn-outline">Case launching soon</div>
        </div>
      ) : null}
      <h6 className="project-title">{title}</h6>
      {/* </Link> */}
      <LabelsWrapper labels={labels} />
    </Col>
  )
}

Project.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  offset: PropTypes.number,
  labels: PropTypes.array,
  link: PropTypes.string,
  comingSoon: PropTypes.bool,
}

export default Project
