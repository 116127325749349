import * as React from "react"
import PropTypes from "prop-types"

const LabelsWrapper = ({labels}) => {
    function labelsBuilder(){
        return labels.map((label, key)=>{
            if(label==='loyall'){
                return (
                    <div key={key} className={`label-${label}-wrapper label-wrapper`}>
                        <span className="label-dot"></span>
                        <span className="label-dot"></span>
                        <span className="label-dot"></span>
                        <span className="label-dot"></span>
                        <span className="label-name">{label}</span>
                    </div>
                )
            }
            return (
                <div  key={key} className={`label-${label}-wrapper label-wrapper`}>
                    <span className="label-dot"></span>
                    <span className="label-name">{label}</span>
                </div>
            )
        })
    }

    return (
        <div className="labels-wrapper">
            {labelsBuilder()}
        </div>
    )
}
LabelsWrapper.propTypes = {
    labels: PropTypes.array
  }
export default LabelsWrapper
