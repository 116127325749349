import * as React from "react"
import Image from "./image"

const ScrollParallax = ({content}) => {
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const spaceHolder = document.querySelector('.scroll-parallax-space-holder');
    const horizontal = document.querySelector('.scroll-parallax-horizontal');
    const offsetSticky = () => {
      const sticky = document.querySelector('.scroll-parallax-sticky');
      horizontal.style.transform = `translateX(-${sticky.offsetTop}px)`;
      }
      function calcDynamicHeight(ref) {
        const vw = window.innerWidth;
        const objectWidth = ref.scrollWidth;
        const objectHeight = ref.offsetHeight;
        if(vw<560){
          return objectWidth - vw + objectHeight; 
        }
        if(vw<1025){
          return objectWidth - vw + objectHeight+55; 
        }
        if(vw<1560){
          return objectWidth - vw + objectHeight+250; 
        }
        if(vw<1650){
          return objectWidth - vw + objectHeight+350; 
        }
        return (1650 + objectHeight)/2; // 150 is the padding (in pixels) desired on the right side of the .cards container. This can be set to whatever your styles dictate
      }
      const resizeHorizontal = () => {
        spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`;
      }
      if(spaceHolder === null || horizontal === null || spaceHolder === undefined || horizontal === undefined){
        
      }else{
        spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`;
        window.addEventListener('scroll', offsetSticky);
        window.addEventListener('resize', resizeHorizontal);
      }
      return () => {
        window.removeEventListener("scroll", offsetSticky);
        window.removeEventListener("resize", resizeHorizontal);
      }
    }
  }, [])

  return (
      <div className="scroll-parallax-space-holder">
        <div className="scroll-parallax-sticky">
          <div className="scroll-parallax-horizontal">
            <section role="feed" className="cards">
                {
                  content.map((element, i) => {
                    return (
                      <div className="card sample-card border-0" key={i}>
                        <Image fadeIn={true} imgsrc={element.image} />
                        <div className="mt-4 title d-flex">
                          {element.logo === "" ? null : element.logo}
                          {element.title === "" ? null : 
                            <h6 className="">{element.title}</h6>
                          }
                        </div>
                        {element.text === "" ? null : 
                          <p>{element.text}</p>
                        }
                      </div>
                    )
                  })
                }
            </section>
          </div>
        </div>
      </div>
  )
}
export default ScrollParallax
