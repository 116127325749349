import * as React from "react"
import { Container, Row} from "react-bootstrap"
import Scrollspy from 'react-scrollspy'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Introduction from "../components/pageIntroduction"
import TitleTextCol from "../components/titleTextCol"
import BlockIntroduction from "../components/blockIntroduction"
import TextImage from "../components/textImage"
import HorizontalSlider from "../components/horizontalSlider"
import Cta from "../components/cta"
import HeaderImageArrowDown from "../components/headerImageArrowDown"
import HeaderTextArrowDown from "../components/headerTextArrowDown"
import ReadProcessBar from "../components/readProgressBar"
import Project from "../components/project"
import StickyMenu from "../components/stickyMenu"
import TitleText from "../components/textTitle"
// import Image from "../components/elements/image"
import HooxBrandmark from "../images/hoox-brandmark.inline.svg"
import SitenuBrandmark from "../images/sitenu-brandmark.inline.svg"
import SendtodeliverBrandmark from "../images/sendtodeliver-brandmark.inline.svg"
import WildeBrandmark from "../images/wilde-brandmark.inline.svg"

const IndexPage = (props) => {
  const readProgressBar = React.createRef();
  return(
    <Layout>
      <ReadProcessBar target={readProgressBar} />
      <div className="page" ref={readProgressBar}>
        <Seo title="Home" />
        <Introduction
          title="We are loyalty makers."
          subtitle="Wij zijn een digital lifecycle agency: we hebben de complete digitale mix om je bedrijf op een duurzame manier te laten groeien en je klanten Loyall te maken."
        />
        <TitleTextCol
          title="Het digitale landschap verandert in een rap tempo."
          paragh1="En daar willen alle organisaties op inspelen. Maar wat als je niet alle onderdelen voor een succesvol resultaat weet samen te voegen? Waar design nodig is, komt vaak zowel marketing als development om de hoek kijken. Als we online marketing inzetten kan dit niet zonder strak design. Waar uitdagingen overwonnen moeten worden, is er niet één oplossing."
          paragh2="Elk ingrediënt van de digital lifecycle is even belangrijk; ze versterken elkaar. En wij hebben alle ingrediënten in huis."
        />
        <BlockIntroduction
          title="De labels."
          intro={
            <h4>
              Ons team bestaat uit digitale lifecycle experts: we weten wat nodig is om jouw doelgroep in loyale klanten te veranderen.
            </h4>
          }
        />
        <BlockIntroduction
          title="De labels."
          intro={
            <h2>
              Let’s <span className="text-gradient">disco</span>ver the
              possibilities together.
            </h2>
          }
          customIntro={true}
        />
        <HeaderTextArrowDown
          text="Just fill this simple form in and we will contact you promptly to discuss your project. Hate forms? Drop us a line at info@loyall.co or sales@loyall.co"
          elementId="intro-discovery"
        />
        <TextImage
          title="Waar het allemaal om draait"
          intro="Met de digital lifecyle investeer je in krachtige en duurzame relaties met je doelgroep."
          paragh="De lifecycle draait erom aandacht te trekken van je doelgroep, om vervolgens banden te leggen en relaties aan te gaan. We willen dat websitebezoekers uitgroeien tot waardevolle leads en klanten, om uiteindelijk je organisatie te promoten binnen hun netwerk. De lifecycle werkt alleen als we design, marketing, development en integraties combineren. Want alleen dan kun je de beste digitale ervaring bieden. En alleen dan blijven mensen betrokken."
          link=""
          linkText=""
          col={true}
        />
        <Container>
          <Row>
            <Project
              type="small"
              image="gatsby-astronaut.png"
              comingSoon={true}
              title="Small cooooool project"
              labels={["development", "design"]}
            />
            <Project
              type="large"
              image="gatsby-astronaut.png"
              comingSoon={true}
              title="Large cooooool project"
              labels={["marketing", "integrations"]}
              offset={1}
            />
            <Project
              type="wide"
              image="gatsby-astronaut.png"
              comingSoon={true}
              title="Wide cooooool project"
              labels={["loyall"]}
              offset={0}
            />
          </Row>
        </Container>
        <StickyMenu
          padding={{top:80,bottom:0,left:15,right:15}}
          stickyElement={
            <div>
              <h6>Inspiration & Sketches</h6>
              <Scrollspy
                    offset ={-120}
                    className="vertical-menu-wrapper"
                    items={ ['inspiration', 'schetsen', 'eerste-ontwerp', 'een-vorm-voor-allemaal'] }
                    currentClassName="vertical-menu-active" >
                <li><a href="#inspiration">Inspiratie</a></li>
                <li><a href="#schetsen">Schetsen</a></li>
                <li><a href="#eerste-ontwerp">Eerste ontwerp</a></li>
                <li><a href="#een-vorm-voor-allemaal">Een vorm voor allemaal</a></li>
              </Scrollspy>
            </div>
          }
          content={
              <div>
                <TitleText title="Inspiratie" id="inspiration" text="Hoe breng je vier unieke labels samen en maak je hier een nieuwe business van? Een grote uitdaging als je te maken hebt met vier compleet verschillende labels met ieder hun eigen branding. Om ideeën op te doen moet je eerst inspiratie opdoen. Wat vinden we tof? Welke vormen, kleuren en stijlen passen bij ons? Hieruit ontstond een moodboard." padding={{top:0, bottom:0, left:0, right:0}} />
                <TitleText title="Schetsen" id="schetsen" text="Hoe breng je vier unieke labels samen en maak je hier een nieuwe business van? Een grote uitdaging als je te maken hebt met vier compleet verschillende labels met ieder hun eigen branding. Om ideeën op te doen moet je eerst inspiratie opdoen. Wat vinden we tof? Welke vormen, kleuren en stijlen passen bij ons? Hieruit ontstond een moodboard." padding={{top:0, bottom:0, left:0, right:0}} />
                <TitleText title="Eerste ontwerp" id="eerste-ontwerp" text="Hoe breng je vier unieke labels samen en maak je hier een nieuwe business van? Een grote uitdaging als je te maken hebt met vier compleet verschillende labels met ieder hun eigen branding. Om ideeën op te doen moet je eerst inspiratie opdoen. Wat vinden we tof? Welke vormen, kleuren en stijlen passen bij ons? Hieruit ontstond een moodboard." padding={{top:0, bottom:0, left:0, right:0}} />
                <TitleText title="Een vorm voor allemaal" id="een-vorm-voor-allemaal" text="Hoe breng je vier unieke labels samen en maak je hier een nieuwe business van? Een grote uitdaging als je te maken hebt met vier compleet verschillende labels met ieder hun eigen branding. Om ideeën op te doen moet je eerst inspiratie opdoen. Wat vinden we tof? Welke vormen, kleuren en stijlen passen bij ons? Hieruit ontstond een moodboard." padding={{top:0, bottom:0, left:0, right:0}} />
              </div>
            }
        />
         <Cta
          verticalStyling={false}
          title="De labels."
          intro="Ons team bestaat uit digitale lifecycle experts: wij weten wat nodig is om jouw doelgroep in loyale klanten te veranderen."
          link="/onze-aanpak"
          linkText="Onze aanpak"
        />
        <HorizontalSlider 
          content={[
            {
              "image": "loyall-labels-design-image.jpg", 
              "logo":  <WildeBrandmark />,
              "title": "Design", 
              "text": "Onze designers ontdekken de dingen die jouw bedrijf mooier maken, brengen ze tot leven en geven er een betekenisvolle identiteit aan. Een mooie website werkt niet zonder connectie met je doelgroep, dus bouwen wij aan duurzame relaties. Met design ontwerpen we om te inspireren."
            },
            {
              "image": "loyall-labels-marketing-image.jpg", 
              "logo":  <SendtodeliverBrandmark />,
              "title": "Marketing", 
              "text": "Onze marketeers zorgen ervoor zorgen voor de meerwaarde die je biedt aan je doelgroep. Hoe benaderen we ze? Wat hebben we ze te vertellen? Hoe stimuleren we ze om over te gaan tot actie? Met marketing lokken we een reactie uit."
            },
            {
              "image": "loyall-labels-development-image.jpg", 
              "logo":  <SitenuBrandmark />,
              "title": "Development", 
              "text": "Hoe heeft jouw bedrijf interactie met de buitenwereld? Onze developers zorgen voor een website die meer doet dan alleen functioneel zijn. Ze zijn energiek, in beweging en geven een holistische belevenis aan bezoekers. Met development zetten we de versnelling in."
            },
            {
              "image": "loyall-labels-integrations-image.jpg",
              "logo":  <HooxBrandmark />, 
              "title": "Integrations", 
              "text": "Hoe houd je de connectie met je huidige en toekomstige klanten? Wat doe je met al je verzamelde data? Waar verzamel je eigenlijk je data? Wij zorgen dat je al je werkzaamheden vanaf één locatie kunt doen: van sales tot marketing, van service tot partnerships. Automatiseer je taken, maak dashboards met real time data en zorg voor een uitmuntende service. Met integraties leggen we duurzame verbindingen."
            }
          ]}
        />
        <HeaderImageArrowDown
          imageBig={"635x460.png"}
          imageSmall={"635x460.png"}
          elementId="rebranding-story-header"
          delay={100}
          animationDuration={500}
        />
      </div>
    </Layout>
  )
}

export default IndexPage
